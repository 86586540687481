import axios from "axios";
import { BASE_URL } from "./constant";

let speaking = false;

export const speakMessage = (message, lang) => {
  if ("speechSynthesis" in window) {
    const speech = new SpeechSynthesisUtterance();
    console.log("speech: ", speech);

    // Set volume (0 to 1)
    speech.volume = 1; // Adjust as needed

    // Set pitch (0 to 2)
    speech.pitch = 1; // Adjust as needed

    // Set language to Arabic
    // speech.lang = speakLang;
    // speech.lang = 'zh-CN';
    speech.lang = lang;

    // Set the text in Arabic along with phonetic pronunciation if needed
    speech.text = message;

    // Set rate (speed)
    speech.rate = 0.8; // Adjust as needed
    console.log("speech 2: ", speech);
    if (!speaking) {
      speaking = true;
      window.speechSynthesis.speak(speech);
      console.log("speaking speech");
      speech.onend = () => {
        // When speech ends, reset speaking to false
        speaking = false;
      };
      speech.onerror = (event) => {
        // Handle speech synthesis errors here
        console.error("Speech synthesis error:", event.error);
        speaking = false; // Reset speaking flag
      };
    }
  } else {
    console.error("Speech Synthesis API not supported in this browser");
  }
};

export const translate = async (text, lang, translatedLang) => {
  const options = {
    method: "POST",
    url: "https://api.cognitive.microsofttranslator.com/translate",
    params: {
      "to": translatedLang,
      "api-version": "3.0",
      from: lang,
  },
    headers: {
      "content-type": "application/json",
      "Ocp-Apim-Subscription-Key": "ace2322a5f15453c86811013ab66efce",
      "Ocp-Apim-Subscription-Region": "qatarcentral",
    },
    data: [
      {
        Text: text,
      },
    ],
  };

  try {
    if (lang !== translatedLang) {
      const response = await axios.request(options);
      return response.data[0].translations[0].text;
    } else {
      return text;
    }
  } catch (error) {
    console.error(error);
  }
};

export const getLastNWords = (inputString, n) => {
  // Split the string into an array of words
  let wordsArray = inputString.trim().split(/\s+/);

  // Extract the last n words
  let lastNWordsArray = wordsArray.slice(-n);

  // Join the words back into a string
  let resultString = lastNWordsArray.join(" ");

  return resultString;
};

export const generateUniqueId = () => {
  const timestamp = Date.now(); // Current timestamp in milliseconds
  const randomNum = Math.random().toString(36).substr(2, 9); // Random string for uniqueness
  return `id-${timestamp}-${randomNum}`;
};

export function isObjectEmpty(obj) {
  return obj == null || Object.keys(obj).length === 0;
}

export const sendStudentQuestion = async (text, studentName, studentLang) => {
  const options = {
    method: "POST",
    // url: "https://kalifa-hope-api.tech-and-beyond.com/api/v1/student-questions",
    url: "https://kalifa-hope-api.dreamit.technology/api/v1/student-questions",
    headers: {
      "content-type": "application/json",
    },
    data: {
      message: text,
      student_name: studentName,
      language: studentLang,
    },
  };

  try {
    const response = await axios.request(options);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const getStudentQuestions = async (date) => {
  const options = {
    method: "GET",
    url: `${BASE_URL}/student-questions?date=${date}`,
    headers: {
      "content-type": "application/json",
    },
  };

  try {
    const response = await axios.request(options);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const getUTCDate = () => {
  const now = new Date();

  const year = now.getUTCFullYear();
  const month = String(now.getUTCMonth() + 1).padStart(2, "0");
  const day = String(now.getUTCDate()).padStart(2, "0");
  const hours = String(now.getUTCHours()).padStart(2, "0");
  const minutes = String(now.getUTCMinutes()).padStart(2, "0");
  const seconds = String(now.getUTCSeconds()).padStart(2, "0");

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  // return now.toISOString();
};

export const guestLogin = async (name, type) => {
  const options = {
    method: "POST",
    url: `${BASE_URL}/auth/guest-login`,
    headers: {
      "content-type": "application/json",
    },
    data: {
      name: name,
      type: type,
    },
  };

  try {
    const response = await axios.request(options);
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const updateProfile = async (name, user_id) => {
  const options = {
    method: "POST",
    url: `${BASE_URL}/auth/update-profile`,
    headers: {
      "content-type": "application/json",
    },
    data: {
      name: name,
      id: user_id,
    },
  };

  try {
    const response = await axios.request(options);
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const login = async (email, password, type) => {
  const options = {
    method: "POST",
    url: `${BASE_URL}/auth/login`,
    headers: {
      "content-type": "application/json",
    },
    data: {
      email: email,
      password: password,
      type: type,
    },
  };

  try {
    const response = await axios.request(options);
    return response;
  } catch (error) {
    console.error(error);
  }
};
export const register = async (email, name, password, type) => {
  const options = {
    method: "POST",
    url: `${BASE_URL}/auth/register`,
    headers: {
      "content-type": "application/json",
    },
    data: {
      email: email,
      name: name,
      password: password,
      type: type,
    },
  };

  try {
    const response = await axios.request(options);
    return response;
  } catch (error) {
    console.error(error);
  }
};

