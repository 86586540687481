import React, { useEffect, useState } from "react";
import { guestLogin, login } from "./utils";
import { Link, useNavigate } from "react-router-dom";

const TeacherLogin = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [teacherId, setTeacherId] = useState(
    sessionStorage.getItem("teacherId") || ""
  );

  const navigate = useNavigate();

  const handleSkip = (e) => {
    guestLogin("", "teacher").then((res) => {
      if (res.data.success) {
        console.log(res.data.data.user);
        // setStudentName(res.data.data.user.name);
        sessionStorage.setItem("teacherName", res.data.data.user.name);
        sessionStorage.setItem("teacherId", res.data.data.user._id);
        navigate("/teacher");
      } else {
        alert(res.data.message);
      }
    });
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");

    try {
      login(email, password, "teacher").then((res) => {
        if (res.data.success) {
          console.log(res.data.data.user);
          sessionStorage.setItem("teacherId", res.data.data.user._id);
          sessionStorage.setItem("teacherName", res.data.data.user.name);
          navigate("/teacher");
        } else {
          alert(res.data.message);
          setLoading(false);
        }
        // sessionStorage.setItem("studentId", 12);
      });
    } catch (err) {
      setError("Login failed. Please check your credentials.");
      setLoading(false);
    }
  };

  useEffect(() => {
    if(teacherId !== ''){
      navigate('/teacher')
    }
  }, [])

  return (
    <div className="login_wrapper">
      <div className="left_section">
        {/* <div className="left_header">
          <div className="right_image">
            <img className="logo logo_login" src="../education.png" />
          </div>
          <div className="left_image">
            <img className="logo logo_login" src="../dreamit.png" />
          </div>
        </div> */}
        <div className="main_logo">
          <img className="logo" src="../logo_s2h.png" />
          <h1 className="student_title"> الإصدار الأول / version 1</h1>
        </div>
      </div>
      <div className="right_section">
        <form className="form" onSubmit={handleLogin}>
          <div className="form_section">
            <h1 className="student_title">Login</h1>
            <div>
              <input
                type="email"
                className="form_input"
                value={email}
                placeholder="Email"
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div>
              <input
                type="password"
                placeholder="Password"
                className="form_input"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            {error && <p style={{ color: "red" }}>{error}</p>}
            <div className="form_button_section">
              <button className="button button_small" type="submit" disabled={loading}>
                {loading ? "Logging in..." : "Login"}
              </button>
              <span className="or_text small_text">Or</span>
              <button
                onClick={handleSkip}
                className="button button_small light-btn"
                type="button"
                disabled={loading}
              >
                {"Skip"}
              </button>
            </div>
          </div>
          <div className="form_footer">
            Don't have an account ?{" "}
            <Link className="link" to="/teacher-register">
              Sign up here
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default TeacherLogin;
